<template>
  <div class="text-black my-3 fs-14" id="TemplateViewPhieugGMTM">
    <div class="sticky top-0 flex justify-content-end bg-white text-base font-semibold z-50 mb-3">
      <a
        v-if="form.pdf_url"
        target="_blank"
        :href="form.pdf_url"
        class="btn bg-pri bd-pri text-white"
      >Xem file gốc</a>
      <button
        class="btn border-none text-black px-3 mx-2 fs-14 fw-500"
        @click="exportPdf()"
        :disabled="loading"
      >
        <div class="loading" v-if="loading">
          <div class="spinner-border mr-2" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M16 14H17C17.2652 14 17.5196 13.8946 17.7071 13.7071C17.8946 13.5196 18 13.2652 18 13V7C18 6.73478 17.8946 6.48043 17.7071 6.29289C17.5196 6.10536 17.2652 6 17 6H3C2.73478 6 2.48043 6.10536 2.29289 6.29289C2.10536 6.48043 2 6.73478 2 7V13C2 13.2652 2.10536 13.5196 2.29289 13.7071C2.48043 13.8946 2.73478 14 3 14H4V9H16V14ZM4 4V0H16V4H17C17.7956 4 18.5587 4.31607 19.1213 4.87868C19.6839 5.44129 20 6.20435 20 7V13C20 13.7956 19.6839 14.5587 19.1213 15.1213C18.5587 15.6839 17.7956 16 17 16H16V20H4V16H3C2.20435 16 1.44129 15.6839 0.87868 15.1213C0.31607 14.5587 0 13.7956 0 13L0 7C0 6.20435 0.31607 5.44129 0.87868 4.87868C1.44129 4.31607 2.20435 4 3 4H4ZM6 18H14V11H6V18ZM6 4H14V2H6V4Z"
            fill="#03267A"
          />
        </svg>
        {{ $t('multidisciplinary_board.lbl_print') }}
      </button>
      <button
        class="btn border-none text-black px-3 mx-2 fs-14 fw-500"
        @click="ModalUpdate(true)"
        :disabled="loading"
      >
        <svg
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15.75 8.99995C15.5511 8.99995 15.3603 9.07897 15.2197 9.21962C15.079 9.36028 15 9.55104 15 9.74995V14.25C15 14.4489 14.921 14.6396 14.7803 14.7803C14.6397 14.9209 14.4489 15 14.25 15H3.75C3.55109 15 3.36032 14.9209 3.21967 14.7803C3.07902 14.6396 3 14.4489 3 14.25V3.74995C3 3.55104 3.07902 3.36028 3.21967 3.21962C3.36032 3.07897 3.55109 2.99995 3.75 2.99995H8.25C8.44891 2.99995 8.63968 2.92094 8.78033 2.78028C8.92098 2.63963 9 2.44887 9 2.24995C9 2.05104 8.92098 1.86028 8.78033 1.71962C8.63968 1.57897 8.44891 1.49995 8.25 1.49995H3.75C3.15326 1.49995 2.58097 1.73701 2.15901 2.15896C1.73705 2.58092 1.5 3.15322 1.5 3.74995V14.25C1.5 14.8467 1.73705 15.419 2.15901 15.8409C2.58097 16.2629 3.15326 16.5 3.75 16.5H14.25C14.8467 16.5 15.419 16.2629 15.841 15.8409C16.2629 15.419 16.5 14.8467 16.5 14.25V9.74995C16.5 9.55104 16.421 9.36028 16.2803 9.21962C16.1397 9.07897 15.9489 8.99995 15.75 8.99995ZM4.5 9.56995V12.75C4.5 12.9489 4.57902 13.1396 4.71967 13.2803C4.86032 13.4209 5.05109 13.5 5.25 13.5H8.43C8.5287 13.5005 8.62655 13.4816 8.71793 13.4443C8.80931 13.407 8.89242 13.352 8.9625 13.2825L14.1525 8.08495L16.2825 5.99995C16.3528 5.93023 16.4086 5.84728 16.4467 5.75589C16.4847 5.66449 16.5043 5.56646 16.5043 5.46745C16.5043 5.36845 16.4847 5.27042 16.4467 5.17902C16.4086 5.08763 16.3528 5.00468 16.2825 4.93495L13.1025 1.71745C13.0328 1.64716 12.9498 1.59136 12.8584 1.55329C12.767 1.51521 12.669 1.49561 12.57 1.49561C12.471 1.49561 12.373 1.51521 12.2816 1.55329C12.1902 1.59136 12.1072 1.64716 12.0375 1.71745L9.9225 3.83995L4.7175 9.03745C4.64799 9.10753 4.593 9.19065 4.55567 9.28202C4.51835 9.3734 4.49943 9.47125 4.5 9.56995V9.56995ZM12.57 3.30745L14.6925 5.42995L13.6275 6.49495L11.505 4.37245L12.57 3.30745ZM6 9.87745L10.4475 5.42995L12.57 7.55245L8.1225 12H6V9.87745Z"
            fill="#03267A"
          />
        </svg>
        {{ $t('multidisciplinary_board.lbl_modify_short') }}
      </button>
      <div
        class="text-black mx-2 fs-14 fw-500 h-4 cursor-pointer mb-3"
        @click="backList"
        v-if="!loading"
      >
        <svg
          width="40"
          height="46"
          viewBox="0 0 48 49"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M26.6276 24.7096L33.6976 17.6376C33.8887 17.4531 34.041 17.2324 34.1458 16.9884C34.2507 16.7444 34.3058 16.4819 34.3081 16.2164C34.3104 15.9508 34.2598 15.6874 34.1593 15.4416C34.0587 15.1959 33.9102 14.9725 33.7224 14.7848C33.5346 14.597 33.3113 14.4485 33.0655 14.3479C32.8198 14.2473 32.5564 14.1967 32.2908 14.1991C32.0253 14.2014 31.7628 14.2565 31.5188 14.3613C31.2748 14.4662 31.0541 14.6185 30.8696 14.8096L23.7976 21.8796L16.7276 14.8096C16.5431 14.6185 16.3225 14.4662 16.0784 14.3613C15.8344 14.2565 15.572 14.2014 15.3064 14.1991C15.0409 14.1967 14.7775 14.2473 14.5317 14.3479C14.2859 14.4485 14.0626 14.597 13.8748 14.7848C13.6871 14.9725 13.5386 15.1959 13.438 15.4416C13.3374 15.6874 13.2868 15.9508 13.2891 16.2164C13.2914 16.4819 13.3466 16.7444 13.4514 16.9884C13.5563 17.2324 13.7086 17.4531 13.8996 17.6376L20.9696 24.7076L13.8996 31.7796C13.7086 31.964 13.5563 32.1847 13.4514 32.4287C13.3466 32.6728 13.2914 32.9352 13.2891 33.2007C13.2868 33.4663 13.3374 33.7297 13.438 33.9755C13.5386 34.2213 13.6871 34.4446 13.8748 34.6323C14.0626 34.8201 14.2859 34.9686 14.5317 35.0692C14.7775 35.1698 15.0409 35.2204 15.3064 35.2181C15.572 35.2157 15.8344 35.1606 16.0784 35.0558C16.3225 34.9509 16.5431 34.7986 16.7276 34.6076L23.7976 27.5376L30.8696 34.6076C31.0541 34.7986 31.2748 34.9509 31.5188 35.0558C31.7628 35.1606 32.0253 35.2157 32.2908 35.2181C32.5564 35.2204 32.8198 35.1698 33.0655 35.0692C33.3113 34.9686 33.5346 34.8201 33.7224 34.6323C33.9102 34.4446 34.0587 34.2213 34.1593 33.9755C34.2598 33.7297 34.3104 33.4663 34.3081 33.2007C34.3058 32.9352 34.2507 32.6728 34.1458 32.4287C34.041 32.1847 33.8887 31.964 33.6976 31.7796L26.6276 24.7076V24.7096Z"
            fill="black"
          />
        </svg>
      </div>
    </div>
    <div ref="viewDetail" id="PhieuGayMeViewDetail" class="mx-auto">
      <div class="row mb-3">
        <div class="col-md-3 flex">
          <div class>
            <div>
              <div>
                <p class="fs-14 mb-0">
                  {{
                  $globalClinic && $globalClinic.organization
                  ? $globalClinic.organization.authority_in_charge
                  : ""
                  }}
                </p>
              </div>
              <div>
                <p class="fs-14">
                  {{
                  $globalClinic && $globalClinic.organization
                  ? $globalClinic.organization.name
                  : ""
                  }}
                </p>
              </div>
              <div class>
                <p class="fs-14 mb-0">{{ $globalClinic ? $globalClinic.name : "" }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 fs-18 flex flex-col justify-center items-center uppercase">
          <div class="text-center">
            <p class="mb-0 text-center fs-14 font">CỘNG HOÀ XÃ HỘI CHỦ NGHĨA VIỆT NAM</p>
            <p class="mb-0 text-center font-weight-bold fs-14 font">Độc lập - Tự do - Hạnh phúc</p>
            <p class="mb-2 text-center">------------------------------------</p>
          </div>
          <p class="fs-16 font-bold">PHIẾU KHÁM GÂY MÊ TRƯỚC MỔ</p>
        </div>
        <div class="col-md-3">
          <!-- <div class="flex">
            <p class="fs-14">Số vào viện:</p>
            <p class="fs-14 mb-0 ml-1"> {{ person.ref_id || '.........' }}</p>
          </div>-->
        </div>
      </div>

      <div class="borderBox p-2">
        <div class="row mb-1">
          <div class="col-md-4">
            <div class>
              <p class="fs-14">- Họ tên người bệnh:</p>
              <p class="fs-13 mb-0 ml-1 font-bold">{{ person.name }}</p>
            </div>
          </div>

          <div class="col-md-2">
            <div class="flex justify-between">
              <div class="flex">
                <p class="fs-14">Nặng:</p>
                <p class="fs-14 ml-1 mb-0">{{ form.weight }}</p>
              </div>
            </div>
          </div>

          <div class="col-md-2">
            <div class="flex justify-between">
              <div class="flex">
                <p class="fs-14">Chiều cao:</p>
                <p class="fs-14 mb-0">{{ form.height }}</p>
              </div>
            </div>
          </div>

          <div class="col-md-4">
            <div class="flex justify-between">
              <div class="flex">
                <p class="fs-14">Bữa ăn cuối trước mổ:</p>
                <p class="fs-14 ml-1 mb-0 flex">
                  {{ form.last_meal_at ? form.last_meal_at : '' }}
                  <span class="ml-2">giờ</span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="row mb-1">
          <div class="col-md-2">
            <div class="flex">
              <p class="fs-14">- Tuổi:</p>
              <p class="fs-14 ml-1 mb-0">{{ person.age }}</p>
            </div>
          </div>

          <div class="col-md-2">
            <div class="flex justify-between">
              <div class="flex">
                <p class="fs-14">Giới:</p>
                <p class="fs-14 ml-1 mb-0">{{ getGender() }}</p>
              </div>
            </div>
          </div>

          <div class="col-md-3">
            <div class="flex justify-between">
              <div class="flex">
                <p class="fs-14">ASA:</p>
                <p class="fs-14 ml-1 mb-0">{{ form.asa }}</p>
              </div>
            </div>
          </div>

          <div class="col-md-3">
            <div class="flex justify-between">
              <div class="flex">
                <p class="fs-14">Mallampati:</p>
                <p class="fs-14 ml-1 mb-0">{{ form.mallampati }}</p>
              </div>
            </div>
          </div>
          <div class="col-md-2">
            <div class="flex justify-between">
              <div class="flex">
                <div class="h-3 w-3 border-box mr-1 flex justify-center items-center relative">
                  <span v-if="form.is_emergency === 1">X</span>
                </div>
                <p class="fs-14">Cấp cứu</p>
                <p class="fs-14 ml-1 mb-0"></p>
              </div>
            </div>
          </div>
        </div>
        <div class="row mb-1">
          <div class="col-md-4">
            <div class="flex">
              <p class="fs-14">- Nhóm máu:</p>
              <p class="fs-14 ml-1 mb-0">{{ form.blood }}</p>
            </div>
          </div>

          <div class="col-md-3">
            <div class="flex justify-between">
              <div class="flex">
                <p class="fs-14">K/c cằm giáp:</p>
                <p class="fs-14 ml-1">{{ form.chin }}</p>
              </div>
            </div>
          </div>

          <div class="col-md-3">
            <div class="flex justify-between">
              <div class="flex">
                <p class="fs-14">Há miệng:</p>
                <p class="fs-14 ml-1">{{ form.mounted }}</p>
              </div>
            </div>
          </div>

          <div class="col-md-2">
            <div class="flex justify-between">
              <div class="flex">
                <div class="h-3 w-3 border-box mr-1 flex justify-center items-center relative">
                  <span v-if="form.is_stomach === 1">X</span>
                </div>
                <p class="fs-14">Dạ dày</p>
                <p class="fs-14 ml-1"></p>
              </div>
            </div>
          </div>
        </div>
        <div class="row mb-1">
          <div class="col-md-4"></div>

          <div class="col-md-3">
            <div class="flex">
              <div class="flex">
                <p class="fs-14">Răng giả</p>
                <p class="fs-14 ml-1"></p>
              </div>
            </div>
          </div>

          <div class="col-md-3">
            <div class="flex">
              <div class="flex">
                <div class="h-3 w-3 border-box mr-1 flex justify-center items-center relative">
                  <span v-if="form.dentures === 1">X</span>
                </div>
                <p class="fs-14">Không</p>
                <p class="fs-14 ml-1"></p>
              </div>
            </div>
          </div>

          <div class="col-md-2"></div>
        </div>
        <div class="row">
          <div class="col-md-4"></div>

          <div class="col-md-3">
            <div class="flex">
              <div class="flex">
                <div class="h-3 w-3 border-box mr-1 flex justify-center items-center relative">
                  <span v-if="form.dentures === 2">X</span>
                </div>
                <p class="fs-14">Tháo được</p>
                <p class="fs-14 ml-1"></p>
              </div>
            </div>
          </div>

          <div class="col-md-3">
            <div class="flex">
              <div class="flex">
                <div class="h-3 w-3 border-box mr-1 flex justify-center items-center relative">
                  <span v-if="form.dentures === 3">X</span>
                </div>
                <p class="fs-14">Cố định</p>
                <p class="fs-14 ml-1"></p>
              </div>
            </div>
          </div>

          <div class="col-md-2"></div>
        </div>
      </div>

      <div class="borderBox p-2">
        <div class="mb-1 mt-1 flex">
          <p class="fs-14">Chẩn đoán:</p>
          <p class="fs-14 ml-1 mb-0" v-html="form.diagnose"></p>
        </div>
        <div class="mb-1 flex">
          <p class="fs-14">Hướng xử trí:</p>
          <p class="fs-14 ml-1 mb-0" v-html="form.treatment"></p>
        </div>
        <div class="mb-1 flex">
          <p class="fs-14">Tiền sử nội khoa:</p>
          <p class="fs-14 ml-1 mb-0" v-html="form.medical_his"></p>
        </div>
        <div class="mb-1 flex">
          <p class="fs-14">Tiền sử ngoại khoa:</p>
          <p class="fs-14 ml-1 mb-0" v-html="form.surgery_his"></p>
        </div>
        <div class="row mb-1">
          <div class="col-md-6 flex">
            <p class="fs-14">Tiền sử gây mê, gây tê:</p>
            <p class="fs-14 ml-1 mb-0" v-html="form.anesthesia_his"></p>
          </div>
          <div class="col-md-6 flex">
            <p class="fs-14">Dị ứng:</p>
            <p class="fs-14 ml-1 mb-0" v-html="form.allergy"></p>
          </div>
        </div>
        <div class="row mb-1">
          <div class="col-md-2">
            <p class="fs-14">Nghiện:</p>
          </div>
          <div class="col-md-3 flex">
            <div class="h-3 w-3 border-box mr-1 flex justify-center items-center relative">
              <span v-if="form.is_tobacco">X</span>
            </div>
            <p class="fs-14">Thuốc lá, thuốc lào</p>
          </div>
          <div class="col-md-2 flex">
            <div class="h-3 w-3 border-box mr-1 flex justify-center items-center relative">
              <span v-if="form.is_alcoho">X</span>
            </div>
            <p class="fs-14">Rượu</p>
          </div>
          <div class="col-md-2 flex">
            <div class="h-3 w-3 border-box mr-1 flex justify-center items-center relative">
              <span class="absolute" v-if="form.is_drug">X</span>
            </div>
            <p class="fs-14">Ma túy</p>
          </div>
          <div class="col-md-3 flex">
            <div class="h-3 w-3 border-box mr-1 flex justify-center items-center relative">
              <span v-if="form.is_infectious">X</span>
            </div>
            <p class="fs-14">Bệnh lây nhiễm</p>
          </div>
        </div>
        <div class="flex">
          <p class="fs-14">Thuốc đang điều trị:</p>
          <p class="fs-14 ml-1 mb-0" v-html="form.treat_medication"></p>
        </div>
      </div>

      <div class="borderBox p-2">
        <div class="mb-1 flex">
          <p class="fs-14">Khám lâm sàng:</p>
          <p class="fs-14 ml-1 mb-0" v-html="form.clinical_examination"></p>
        </div>
        <div class="row mb-1">
          <div class="col-md-6 flex">
            <p class="fs-14">Tuần hoàn:</p>
            <p class="fs-14 ml-1 mb-0" v-html="form.cyclic"></p>
          </div>
          <div class="col-md-3 flex">
            <p class="fs-14">M:</p>
            <p class="fs-14 ml-1 mb-0">{{ form.pulse }}</p>
          </div>
          <div class="col-md-3 flex">
            <p class="fs-14">HA:</p>
            <p class="fs-14 ml-1 mb-0">
              <span>{{ form.blood_pressure_high }}</span>
              <span class="mx-1">/</span>
              <span>{{ form.blood_pressure }}</span>
            </p>
          </div>
        </div>
        <div class="mb-1 flex">
          <p class="fs-14">Hô hấp:</p>
          <p class="fs-14 ml-1 mb-0" v-html="form.respiratory"></p>
        </div>
        <div class="row mb-1">
          <div class="col-md-6 flex">
            <p class="fs-14">Thần kinh:</p>
            <p class="fs-14 ml-1 mb-0" v-html="form.nerve"></p>
          </div>
          <div class="col-md-6 flex">
            <p class="fs-14">Cột sống:</p>
            <p class="fs-14 ml-1 mb-0" v-html="form.spine"></p>
          </div>
        </div>
        <div class="row mb-1">
          <div class="col-md-6 flex">
            <p class="fs-14">Xét nghiệm bất thường:</p>
            <p class="fs-14 ml-1 mb-0" v-html="form.abnormal_examination"></p>
          </div>
          <div class="col-md-6 flex">
            <p class="fs-14">Yêu cầu bổ sung:</p>
            <p class="fs-14 ml-1 mb-0" v-html="form.additional_request"></p>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 flex">
            <p class="fs-14">Dự kiến cách và thuốc vô cảm:</p>
            <p class="fs-14 ml-1 mb-0" v-html="form.anaesthetic_method"></p>
          </div>
          <div class="col-md-6 flex">
            <p class="fs-14">Dự kiến giảm đau sau mổ:</p>
            <p class="fs-14 ml-1 mb-0" v-html="form.pain_relief"></p>
          </div>
        </div>
      </div>

      <div class="borderBox border-b p-2">
        <div class="mb-1 row h-16">
          <div class="col-md-6 flex">
            <p class="fs-14">Y lệnh trước mổ Và thuốc tiền mê:</p>
            <p class="fs-14 ml-1 mb-0" v-html="form.medical_order"></p>
          </div>
          <div class="col-md-6">
            <p class="fs-14">Họ tên, chữ ký của Điều dưỡng thực hiện:</p>
            <p
              class="fs-14 font-bold mt-5 text-center"
            >{{ (form.nurse && form.nurse.name) || form.nurse_name }}</p>
          </div>
        </div>
        <div class="mb-1 row mt-5">
          <div class="col-md-6 flex">
            <p class="fs-14">Ngày khám:</p>
            <p class="fs-14 ml-1 mb-0">
              {{
              form.examination_date
              ? formatDateTime(form.examination_date)
              : ""
              }}
            </p>
          </div>
          <div class="col-md-6 flex">
            <p class="fs-14">Ngày thăm trước mổ:</p>
            <p class="fs-14 ml-1 mb-0">
              {{
              form.visit_before_date
              ? formatDateTime(form.visit_before_date)
              : ""
              }}
            </p>
          </div>
        </div>
        <div class="row h-28">
          <div class="col-md-6">
            <p class="fs-14">Họ tên, chữ ký của Bác sĩ gây mê</p>
            <p
              class="fs-14 font-bold mt-5 ml-5"
            >{{ (form.anesthesia_doctor && form.anesthesia_doctor.name) || form.anesthesia_doctor_name || ''}}</p>
          </div>
          <div class="col-md-6">
            <p class="fs-14">Họ tên, chữ ký của Bác sĩ gây mê</p>
            <p
              class="fs-14 font-bold mt-5 text-center"
            >{{ (form.anesthesia_visit_doctor && form.anesthesia_visit_doctor.name) || form.anesthesia_doctor_visit_name || '' }}</p>
          </div>
        </div>
      </div>
    </div>
    <modalUpdateForm :type="'update'" @onUpdate="putAnesthesia($event)" />
  </div>
</template>

      <script>
// import _ from 'lodash'
import modalUpdateForm from './ModalPhieuGayMeHoiSuc'
import html2pdf from 'html2pdf.js'
import { mapState } from 'vuex'
import appUtils from '../../../utils/appUtils'
export default {
  name: 'GayMeDetail',
  components: { modalUpdateForm },
  data () {
    return {
      form: {
        date: null,
        nurse_id: null,
        anesthesia_doctor_visit_id: null,
        examination_date: null,
        visit_before_date: null,
        anesthesia_doctor_id: '',
        last_meal_at: '',
        pulse: '',
        weight: '',
        height: '',
        blood: '',
        chin: '',
        mounted: '',
        asa: 1,
        pain_relief: '',
        mallampati: 1,
        is_stomach: 0,
        is_emergency: null,
        dentures: 0,
        treatment: '',
        medical_his: '',
        surgery_his: '',
        anesthesia_his: '',
        allergy: '',
        is_tobacco: 0,
        is_alcoho: 0,
        is_drug: 0,
        is_infectious: 0,
        treat_medication: '',
        clinical_examination: '',
        cyclic: '',
        blood_pressure: '',
        respiratory: '',
        nerve: '',
        spine: '',
        abnormal_examination: '',
        additional_request: '',
        anaesthetic_method: '',
        medical_order: '',
        pre_anesthetic: '',
        diagnose: ''
      },
      options1: [
        {
          value: '2',
          label: '2'
        },
        {
          value: '3',
          label: '3'
        },
        {
          value: '4',
          label: '4'
        },
        {
          value: '5',
          label: '5'
        }
      ],
      options2: [
        {
          value: '2',
          label: '2'
        },
        {
          value: '3',
          label: '3'
        },
        {
          value: '4',
          label: '4'
        }
      ],
      doctors: null,
      loading: false,
      type: null
    }
  },
  mounted () {
    this.getDoctors()
    this.getShowAnesthesia()
    this.type = this.$route.query.type
  },
  watch: {
    '$route.query.type' (val) {
      this.type = val
    }
  },
  computed: {
    ...mapState({
      person: state => state.person
    }),
    ...mapState({
      medicalRecordMain: state => state.medicalRecordMain
    })
  },
  methods: {
    formatDate (date) {
      return window.moment(date).format('HH:mm DD/MM/YYYY')
    },
    formatDateTime (date) {
      return window.moment(date).format('DD/MM/YYYY')
    },
    getGender () {
      return appUtils.getGender(this.person.gender)
    },
    async getDoctors () {
      const clinic_id = this.$clinicId
      const res = await this.$rf
        .getRequest('DoctorRequest')
        .getDoctorsOfWorkspace(clinic_id, { limit: 1000 })
      this.doctors = res.data?.data
    },
    async getShowAnesthesia () {
      let id = this.$route.query.vId
      if (!id) {
        return
      }
      this.loading = true
      await this.$rf
        .getRequest('DoctorRequest')
        .getShowAnesthesia(id)
        .then(r => {
          this.form = r.data
        })
        .finally(() => {
          this.loading = false
        })
    },
    async postAnesthesia () {
      const params = {
        medical_record_id: this.$route.params.id,
        ...this.form
      }
      await this.$rf
        .getRequest('DoctorRequest')
        .postAnesthesia(params)
        .then(r => {
          this.$toast.open({
            message: 'Tải lên thành công',
            type: 'success'
          })
        })
        .finally(() => {
          this.loading = false
        })
    },
    async putAnesthesia (event) {
      const id = this.$route.query.vId
      this.loading = true
      const params = {
        ...event,
        medical_record_id: this.$route.params.id
      }
      await this.$rf
        .getRequest('DoctorRequest')
        .putAnesthesia(id, params)
        .then(r => {
          this.getShowAnesthesia()
          this.$toast.open({
            message: 'Chỉnh sửa thành công',
            type: 'success'
          })
        })
        .finally(() => {
          this.loading = false
        })
    },
    ModalUpdate (show) {
      window.$('#modalPhieuGMTM').modal(show ? 'show' : 'hide')
    },
    backList () {
      this.$router.replace({ query: { tab: this.$route.query.tab } })
    },
    exportPdf () {
      this.loading = true
      setTimeout(async () => {
        let options = {
          margin: [7, 11, 7, 7],
          filename: `Phieu_Kham_Gay_Me_${this.form.name}_Ngay_${this.form.date}.pdf`,
          html2canvas: {
            dpi: 1000,
            scale: 6,
            image: { type: 'png', quality: 1 },
            letterRendering: true,
            allowTaint: true,
            logging: true,
            useCORS: true
          },
          jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }
        }
        await html2pdf()
          .from(this.$refs.viewDetail)
          .set(options)
          .save()
        this.loading = false
      }, 10)
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep {
  .el-table--border,
  .el-table--group {
    border: 1px solid #000;
  }
  .el-table--border th.el-table__cell,
  .el-table__fixed-right-patch {
    border-bottom: 1px solid #000 !important;
  }
  .el-table--border .el-table__cell,
  .el-table th.el-table__cell.is-leaf {
    border-right: 1px solid #000 !important;
  }
  div {
    word-break: inherit !important;
  }
  div.cell {
    padding: 0 4px !important;
  }
}
.content-none {
  width: 100%;
  border-bottom: 2px dotted;
}
.borderBox {
  border: 1px solid black;
  border-bottom: none;
}
.border-b {
  border-bottom: 1px solid;
}
.border-box {
  border: 1px solid black;
}
</style>
