<template>
  <div>
    <div
      class="modal fade"
      id="modalPhieuGMTM"
      tabindex="-1"
      role="dialog"
      aria-labelledby="modalPhieuGayMeLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content" v-loading="loading">
          <div class="d-flex justify-content-between p-3">
            <div class="fs-24 fw-700 txt-grey-900 text-center w-100">
              <span>PHIẾU KHÁM GÂY MÊ TRƯỚC MỔ</span>
            </div>
            <button
              type="button"
              id="closeModal"
              class="close bg-white border-0"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true" class="fs-30 fw-500">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row mb-3">
              <div class="col-md-2">
                <label class="mt-1.5">Nhóm máu:</label>
              </div>
              <div class="col-md-5">
                <input v-model="form.blood" class="form--input" placeholder="Nhập nhóm máu" />
              </div>
              <div class="col-md-5">
                <div class="flex justify-center mb-2">
                  <label class="mt-1.5 mr-2">Bữa ăn cuối trước giờ mổ:</label>
                  <input
                    type="number"
                    v-model="form.last_meal_at"
                    placeholder
                    class="form--input txt-grey-900 w-18 h-4"
                  />
                  <label class="mt-1.5 mr-2 ml-2">Giờ</label>
                </div>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-md-2">
                <label class="mt-1.5">ASA:</label>
              </div>
              <div class="col-md-5">
                <el-select class="w-100" v-model="form.asa" placeholder="Chọn">
                  <el-option
                    v-for="item in options1"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </div>
              <div class="col-md-5">
                <div class="row">
                  <div class="col-md-3 offset-3">
                    <div>
                      <el-checkbox
                        class="mr-2"
                        :value="form.is_emergency === 1 ? true : false"
                        v-model="form.is_emergency"
                        :true-label="1"
                        :false-label="0"
                      ></el-checkbox>
                      <label for="form" class>Cấp cứu</label>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div>
                      <el-checkbox
                        class="mr-2"
                        v-model="form.is_stomach"
                        :true-label="1"
                        :false-label="0"
                      ></el-checkbox>
                      <label for="form" class>Dạ dày</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-md-2">
                <label class="mt-1.5">K/c cằm giáp:</label>
              </div>
              <div class="col-md-5">
                <input v-model="form.chin" class="form--input" placeholder />
              </div>
              <div class="col-md-5">
                <div class="row">
                  <div class="col-md-4 flex justify-end">
                    <label class="mt-1.5 mr-2">Chiều cao:</label>
                  </div>
                  <div class="col-md-5">
                    <input v-model="form.height" placeholder class="form--input" type="number"/>
                  </div>
                  <div class="col-md-2">
                    <label class="mt-1.5 mr-2 ml-2">cm</label>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-md-2">
                <label class="mt-1.5">Mallampati:</label>
              </div>
              <div class="col-md-5">
                <el-select class="w-100" v-model="form.mallampati" placeholder="Chọn">
                  <el-option
                    v-for="item in options2"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </div>
              <div class="col-md-5">
                <div class="row">
                  <div class="col-md-4 flex justify-end">
                    <label class="mt-1.5 mr-2">Cân nặng:</label>
                  </div>
                  <div class="col-md-5">
                    <input v-model="form.weight" placeholder class="form--input" type="number"/>
                  </div>
                  <div class="col-md-3">
                    <label class="mt-1.5 mr-2 ml-2">kg</label>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-md-2">
                <label class="mt-1.5">Há miệng:</label>
              </div>
              <div class="col-md-5">
                <input v-model="form.mounted" class="form--input" placeholder />
              </div>
              <div class="col-md-5">
                <div class="row">
                  <div class="col-md-4 flex justify-end">
                    <label class="mt-1.5 mr-2">Mạch:</label>
                  </div>
                  <div class="col-md-5">
                    <input v-model="form.pulse" placeholder class="form--input" type="number"/>
                  </div>
                  <div class="col-md-3">
                    <label class="fs-14 mt-1.5 mr-2 ml-2"></label>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-md-2">
                <label class="mt-1.5">Răng giả:</label>
              </div>
              <div class="col-md-5">
                <div class="row mt-1.5">
                  <div class="col-md-4 flex items-center">
                    <input
                      id="type2"
                      v-model="form.dentures"
                      :value="2"
                      class="mr-2 cursor-pointer"
                      type="radio"
                    />
                    <label class for="type2">Tháo được</label>
                  </div>
                  <div class="col-md-4 flex items-center">
                    <input
                      id="type1"
                      v-model="form.dentures"
                      :value="1"
                      class="mr-2 cursor-pointer"
                      type="radio"
                    />
                    <label class for="type1">Không</label>
                  </div>
                  <div class="col-md-4 flex items-center">
                    <input
                      id="type3"
                      v-model="form.dentures"
                      :value="3"
                      class="mr-2 cursor-pointer"
                      type="radio"
                    />
                    <label for="type3" class>Cố định</label>
                  </div>
                </div>
              </div>
              <div class="col-md-5">
                <div class="row mb-2">
                  <div class="col-md-5 flex items-center justify-end">
                    <label class>Huyết áp(mmHg):</label>
                  </div>
                  <div class="col-md-3">
                    <input v-model="form.blood_pressure_high" placeholder class="form--input" type="number" @input="() => { if(form.blood_pressure_high > 135 || form.blood_pressure_high < 0) { form.blood_pressure_high = 135 }}" />
                  </div>
                  <div class="col-md-1 mt-1.5">/</div>
                  <div class="col-md-3">
                    <input v-model="form.blood_pressure" placeholder class="form--input" type="number" @input="() => { if(form.blood_pressure > 135 || form.blood_pressure < 0) { form.blood_pressure = 135 }}"/>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-md-12">
                <label class="mb-1">Chẩn đoán:</label>
                <div>
                  <CkeditorNormal
                    @onEditorInput="
                    (t) => {
                      form.diagnose = t;
                    }
                  "
                    :prop_text="form.diagnose"
                    :placeholder="'Nhập chẩn đoán'"
                    :class_style="'bg-white p-0 txt-black'"
                    :id="'input_diagnose'"
                  ></CkeditorNormal>
                </div>
              </div>
            </div>

            <div class="row mb-2">
              <div class="col-md-6">
                <label class="mb-1">Hướng xử trí:</label>
                <CkeditorNormal
                  @onEditorInput="
                    (t) => {
                      form.treatment = t;
                    }
                  "
                  :prop_text="form.treatment"
                  :placeholder="'Nhập hướng xử trí'"
                  :class_style="'bg-white p-0 txt-black'"
                  :id="'input_treatment'"
                ></CkeditorNormal>
              </div>
              <div class="col-md-6">
                <label class="mb-1">Tiền sử nội khoa:</label>
                <CkeditorNormal
                  @onEditorInput="
                    (t) => {
                      form.medical_his = t;
                    }
                  "
                  :prop_text="form.medical_his"
                  :placeholder="'Nhập tiền sử nội khoa'"
                  :class_style="'bg-white p-0 txt-black'"
                  :id="'input_medical_his'"
                ></CkeditorNormal>
              </div>
            </div>
            <div class="row mb-2">
              <div class="col-md-6">
                <label class="mb-1">Tiền sử gây mê, gây tê:</label>
                <CkeditorNormal
                  @onEditorInput="
                    (t) => {
                      form.anesthesia_his = t;
                    }
                  "
                  :prop_text="form.anesthesia_his"
                  :placeholder="'Nhập tiền sử gây mê, gây tê'"
                  :class_style="'bg-white p-0 txt-black'"
                  :id="'input_anesthesia_his'"
                ></CkeditorNormal>
              </div>
              <div class="col-md-6">
                <label class="mb-1">Tiền sử ngoại khoa:</label>
                <CkeditorNormal
                  @onEditorInput="
                    (t) => {
                      form.surgery_his = t;
                    }
                  "
                  :prop_text="form.surgery_his"
                  :placeholder="'Nhập tiền sử ngoại khoa'"
                  :class_style="'bg-white p-0 txt-black'"
                  :id="'input_surgery_his'"
                ></CkeditorNormal>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-md-12">
                <label class="mb-1">Dị ứng:</label>
                <CkeditorNormal
                  @onEditorInput="
                    (t) => {
                      form.allergy = t;
                    }
                  "
                  :prop_text="form.allergy"
                  :placeholder="'Nhập dị ứng'"
                  :class_style="'bg-white p-0 txt-black'"
                  :id="'input_allergy'"
                ></CkeditorNormal>
              </div>
              <div class="col-md-6"></div>
            </div>
            <div class="row mb-2">
              <div class="col-md-2">
                <label class>Nghiện:</label>
              </div>
              <div class="col-md-2">
                <div class="flex mb-2">
                  <el-checkbox
                    class="mr-2"
                    v-model="form.is_tobacco"
                    :true-label="1"
                    :false-label="0"
                  ></el-checkbox>
                  <label class="fs-14 ml-2">Thuốc lá, thuốc lào</label>
                </div>
                <div class="flex">
                  <el-checkbox
                    class="mr-2"
                    v-model="form.is_alcoho"
                    :true-label="1"
                    :false-label="0"
                  ></el-checkbox>
                  <label class="fs-14 ml-2">Rượu</label>
                </div>
              </div>
              <div class="col-md-2">
                <div class="flex mb-2">
                  <el-checkbox class="mr-2" v-model="form.is_drug" :true-label="1" :false-label="0"></el-checkbox>
                  <label class="fs-14 ml-2">Ma tuý</label>
                </div>
                <div class="flex">
                  <el-checkbox
                    class="mr-2"
                    v-model="form.is_infectious"
                    :true-label="1"
                    :false-label="0"
                  ></el-checkbox>
                  <label class="fs-14 ml-2">Bệnh lây nhiễm</label>
                </div>
              </div>
            </div>
            <div class="row mb-2">
              <div class="col-md-12">
                <label class="mb-1">Thuốc đang điều trị:</label>
                <CkeditorNormal
                  @onEditorInput="
                    (t) => {
                      form.treat_medication = t;
                    }
                  "
                  :prop_text="form.treat_medication"
                  :placeholder="''"
                  :class_style="'bg-white p-0 txt-black'"
                  :id="'input_treat_medication'"
                ></CkeditorNormal>
              </div>
              <div class="col-md-6"></div>
            </div>
            <div class="row mb-2">
              <div class="col-md-6">
                <label class="mb-1">Khám lâm sàng:</label>
                <CkeditorNormal
                  @onEditorInput="
                    (t) => {
                      form.clinical_examination = t;
                    }
                  "
                  :prop_text="form.clinical_examination"
                  :placeholder="''"
                  :class_style="'bg-white p-0 txt-black'"
                  :id="'input_clinical_examination'"
                ></CkeditorNormal>
              </div>
              <div class="col-md-6">
                <label class="mb-1">Cột sống:</label>
                <CkeditorNormal
                  @onEditorInput="
                    (t) => {
                      form.spine = t;
                    }
                  "
                  :prop_text="form.spine"
                  :placeholder="''"
                  :class_style="'bg-white p-0 txt-black'"
                  :id="'input_spine'"
                ></CkeditorNormal>
              </div>
            </div>
            <div class="row mb-2">
              <div class="col-md-6">
                <label class="mb-1">Tuần hoàn:</label>
                <CkeditorNormal
                  @onEditorInput="
                    (t) => {
                      form.cyclic = t;
                    }
                  "
                  :prop_text="form.cyclic"
                  :placeholder="''"
                  :class_style="'bg-white p-0 txt-black'"
                  :id="'input_cyclic'"
                ></CkeditorNormal>
              </div>
              <div class="col-md-6">
                <label class="mb-1">Yêu cầu bổ sung:</label>
                <CkeditorNormal
                  @onEditorInput="
                    (t) => {
                      form.additional_request = t;
                    }
                  "
                  :prop_text="form.additional_request"
                  :placeholder="''"
                  :class_style="'bg-white p-0 txt-black'"
                  :id="'input_additional_request'"
                ></CkeditorNormal>
              </div>
            </div>
            <div class="row mb-2">
              <div class="col-md-6">
                <label class="mb-1">Hô hấp:</label>
                <CkeditorNormal
                  @onEditorInput="
                    (t) => {
                      form.respiratory = t;
                    }
                  "
                  :prop_text="form.respiratory"
                  :placeholder="''"
                  :class_style="'bg-white p-0 txt-black'"
                  :id="'input_respiratory'"
                ></CkeditorNormal>
              </div>
              <div class="col-md-6">
                <label class="mb-1">Thần kinh:</label>
                <CkeditorNormal
                  @onEditorInput="
                    (t) => {
                      form.nerve = t;
                    }
                  "
                  :prop_text="form.nerve"
                  :placeholder="''"
                  :class_style="'bg-white p-0 txt-black'"
                  :id="'input_nerve'"
                ></CkeditorNormal>
              </div>
            </div>
            <div class="row mb-2">
              <div class="col-md-6">
                <label class="mb-1">Xét nghiệm bất thường:</label>
                <CkeditorNormal
                  @onEditorInput="
                    (t) => {
                      form.abnormal_examination = t;
                    }
                  "
                  :prop_text="form.abnormal_examination"
                  :placeholder="''"
                  :class_style="'bg-white p-0 txt-black'"
                  :id="'input_abnormal_examination'"
                ></CkeditorNormal>
              </div>
              <div class="col-md-6">
                <label class="mb-1">Dự kiến cách và thuốc vô cảm:</label>
                <CkeditorNormal
                  @onEditorInput="
                    (t) => {
                      form.anaesthetic_method = t;
                    }
                  "
                  :prop_text="form.anaesthetic_method"
                  :placeholder="''"
                  :class_style="'bg-white p-0 txt-black'"
                  :id="'input_anaesthetic_method'"
                ></CkeditorNormal>
              </div>
            </div>
            <div class="row mb-2">
              <div class="col-md-6">
                <label class="mb-1">Dự kiến giảm đau sau mổ:</label>
                <CkeditorNormal
                  @onEditorInput="
                    (t) => {
                      form.pain_relief = t;
                    }
                  "
                  :prop_text="form.pain_relief"
                  :placeholder="''"
                  :class_style="'bg-white p-0 txt-black'"
                  :id="'input_pain_relief'"
                ></CkeditorNormal>
              </div>
              <div class="col-md-6">
                <label class="mb-1">Y lệnh trước mổ và thuốc tiền mê:</label>
                <CkeditorNormal
                  @onEditorInput="
                    (t) => {
                      form.medical_order = t;
                    }
                  "
                  :prop_text="form.medical_order"
                  :placeholder="''"
                  :class_style="'bg-white p-0 txt-black'"
                  :id="'input_medical_order'"
                ></CkeditorNormal>
              </div>
            </div>
            <div class="row mb-2">
              <div class="col-md-2">
                <label class="mt-1.5">Ngày khám:</label>
              </div>
              <div class="col-md-4">
                <input v-model="form.examination_date" type="datetime-local" class="form--input" />
              </div>
              <div class="col-md-2">
                <label class="mt-1.5">Bác sĩ gây mê:</label>
              </div>
              <div class="col-md-4">
                <el-select
                  v-model="form.anesthesia_doctor_id"
                  allow-create
                  filterable
                  remote
                  reserve-keyword
                  placeholder="Tìm kiếm tên bác sĩ"
                  class="w-100"
                  :remote-method="getDoctor"
                  :loading="loadingDoctor"
                >
                  <el-option
                    v-for="item in doctors"
                    :key="item.id + 'dtr'"
                    :label="item.name"
                    :value="item.user_id"
                  ></el-option>
                </el-select>
              </div>
            </div>
            <div class="row mb-2">
              <div class="col-md-2">
                <label class="mt-1.5">Ngày thăm trước mổ:</label>
              </div>
              <div class="col-md-4">
                <input v-model="form.visit_before_date" type="datetime-local" class="form--input" />
              </div>
              <div class="col-md-2">
                <label class="mt-1.5">Bác sĩ gây mê:</label>
              </div>
              <div class="col-md-4">
                <el-select
                  v-model="form.anesthesia_doctor_visit_id"
                  allow-create
                  filterable
                  remote
                  reserve-keyword
                  placeholder="Tìm kiếm tên bác sĩ"
                  class="w-100"
                  :remote-method="getDoctor"
                  :loading="loadingDoctor"
                >
                  <el-option
                    v-for="item in doctors"
                    :key="item.id + 'dtr'"
                    :label="item.name"
                    :value="item.user_id"
                  ></el-option>
                </el-select>
              </div>
            </div>
            <div class="row mb-2">
              <div class="col-md-6">

              </div>
              <div class="col-md-2">
                <label class="mt-1.5">Y tá:</label>
              </div>
              <div class="col-md-4">
                <el-select
                  v-model="form.nurse_id"
                  allow-create
                  filterable
                  remote
                  reserve-keyword
                  placeholder="Tìm kiếm tên y tá"
                  class="w-100"
                  :remote-method="getDoctor"
                  :loading="loadingDoctor"
                >
                  <el-option
                    v-for="item in doctors"
                    :key="item.id + 'dtr'"
                    :label="item.name"
                    :value="item.user_id"
                  ></el-option>
                </el-select>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button @click="onSubmit" type="button" class="btn bg-pri text-white">Lưu phiếu</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  <script>
import CkeditorNormal from '@/components/Editors/CkeditorNormal.vue'
import moment from 'moment'
import { mapState } from 'vuex'
export default {
  name: 'ModalPGM',
  components: { CkeditorNormal },
  props: ['type'],
  data () {
    return {
      checked: true,
      loadingDoctor: false,
      form: {
        date: moment().format('YYYY-MM-DD HH:mm'),
        nurse_id: null,
        anesthesia_doctor_visit_id: null,
        anesthesia_doctor_visit_name: '',
        examination_date: moment().format('YYYY-MM-DD HH:mm'),
        visit_before_date: moment().format('YYYY-MM-DD HH:mm'),
        anesthesia_doctor_id: '',
        anesthesia_doctor_name: '',
        nurse_name: '',
        last_meal_at: '',
        weight: '',
        height: '',
        blood: '',
        chin: '',
        mounted: '',
        asa: 1,
        mallampati: 1,
        is_stomach: 0,
        is_emergency: 0,
        dentures: 0,
        treatment: '',
        medical_his: '',
        surgery_his: '',
        anesthesia_his: '',
        allergy: '',
        is_tobacco: 0,
        is_alcoho: 0,
        is_drug: 0,
        is_infectious: 0,
        treat_medication: '',
        pulse: '',
        clinical_examination: '',
        cyclic: '',
        blood_pressure: '',
        blood_pressure_high: '',
        respiratory: '',
        nerve: '',
        spine: '',
        abnormal_examination: '',
        additional_request: '',
        anaesthetic_method: '',
        pain_relief: '',
        medical_order: '',
        pre_anesthetic: '',
        diagnose: ''
      },
      vId: this.$route.query.vId,
      options1: [
        {
          value: '2',
          label: '2'
        },
        {
          value: '3',
          label: '3'
        },
        {
          value: '4',
          label: '4'
        },
        {
          value: '5',
          label: '5'
        }
      ],
      options2: [
        {
          value: '2',
          label: '2'
        },
        {
          value: '3',
          label: '3'
        },
        {
          value: '4',
          label: '4'
        }
      ],
      doctors: null,
      loading: false
    }
  },
  computed: {
    ...mapState({
      person: state => state.person,
      medicalRecordMain: state => state.medicalRecordMain
    })
  },
  mounted () {
    this.getDoctor()
    if (this.type === 'update') {
      this.getShowAnesthesia()
    }

    if (this.type === 'create') {
      let self = this

      self.$nextTick(function () {
        window.$('#modalPhieuGMTM').on('shown.bs.modal', function (e) {
          self.form.diagnose =
            self.medicalRecordMain?.treat_dept_main_name || ''
        })

        window.$('#modalPhieuGMTM').on('hidden.bs.modal', function (e) {
          self.form.diagnose = ''
        })
      })
    }
  },
  methods: {
    async getShowAnesthesia () {
      this.loading = true
      let id = this.$route.query.vId
      await this.$rf
        .getRequest('DoctorRequest')
        .getShowAnesthesia(id)
        .then(r => {
          this.form = {
            date: r.data.date,
            nurse_id: r.data.nurse_id || r.data.nurse_name,
            anesthesia_doctor_visit_id: r.data.anesthesia_doctor_visit_id || r.data.anesthesia_doctor_visit_name,
            examination_date: r.data.examination_date,
            visit_before_date: r.data.visit_before_date,
            anesthesia_doctor_id: r.data.anesthesia_doctor_id || r.data.anesthesia_doctor_name,
            weight: r.data.weight,
            height: r.data.height,
            last_meal_at: r.data.last_meal_at || '',
            blood: r.data.blood,
            chin: r.data.chin,
            mounted: r.data.mounted,
            asa: r.data.asa,
            pulse: parseInt(r.data.pulse),
            mallampati: r.data.mallampati,
            is_stomach: r.data.is_stomach,
            is_emergency: r.data.is_emergency,
            dentures: r.data.dentures,
            treatment: r.data.treatment || '',
            medical_his: r.data.medical_his || '',
            surgery_his: r.data.surgery_his || '',
            anesthesia_his: r.data.anesthesia_his || '',
            allergy: r.data.allergy || '',
            is_tobacco: r.data.is_tobacco,
            is_alcoho: r.data.is_alcoho,
            is_drug: r.data.is_drug,
            is_infectious: r.data.is_infectious,
            treat_medication: r.data.treat_medication || '',
            clinical_examination: r.data.clinical_examination || '',
            cyclic: r.data.cyclic || '',
            blood_pressure: r.data.blood_pressure || '',
            blood_pressure_high: r.data.blood_pressure_high || '',
            respiratory: r.data.respiratory || '',
            nerve: r.data.nerve || '',
            spine: r.data.spine || '',
            abnormal_examination: r.data.abnormal_examination || '',
            additional_request: r.data.additional_request || '',
            anaesthetic_method: r.data.anaesthetic_method || '',
            pain_relief: r.data.pain_relief || '',
            medical_order: r.data.medical_order || '',
            pre_anesthetic: r.data.pre_anesthetic || '',
            diagnose: r.data.diagnose || ''
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    onSubmit () {
      if (isNaN(this.form.anesthesia_doctor_id)) {
        this.form.anesthesia_doctor_name = this.form.anesthesia_doctor_id
        this.form.anesthesia_doctor_id = null
      } else {
        this.form.anesthesia_doctor_name = null
      }
      if (isNaN(this.form.anesthesia_doctor_visit_id)) {
        this.form.anesthesia_doctor_visit_name = this.form.anesthesia_doctor_visit_id
        this.form.anesthesia_doctor_visit_id = null
      } else {
        this.form.anesthesia_doctor_visit_name = null
      }
      if (isNaN(this.form.nurse_id)) {
        this.form.nurse_name = this.form.nurse_id
        this.form.nurse_id = null
      } else {
        this.form.nurse_name = null
      }
      if (this.type === 'create') {
        this.form.medical_record_id = this.$route.params.id
        this.$emit('onCreate', this.form)
        this.closeModal()
        window.$('button#closeModal').trigger('click')
      } else {
        this.form.medical_record_id = this.$route.params.id
        this.$emit('onUpdate', this.form)
        this.getShowAnesthesia()
        this.closeModal()
        window.$('button#closeModal').trigger('click')
      }
    },
    getDoctor (search = null) {
      if (this.$globalClinic) {
        if (this.$globalClinic?.org_id) {
          this.getOrgDoctor(search)
        } else {
          this.getClinicDoctor(search)
        }
      }
    },
    async getOrgDoctor (search = null) {
      const params = {
        limit: 50
      }
      if (search) {
        params.search = search
      }
      this.loadingDoctor = true
      await this.$rf
        .getRequest('DoctorRequest')
        .getOrgDoctor(this.$globalClinic?.org_id, params)
        .then(r => {
          this.doctors = r.data
          const hasMe = this.doctors.find(d => d.user_id === this.$user.id)
          if (!hasMe && this.$user.doctor) this.doctors.push(this.$user.doctor)
          this.doctorPaging = r.meta
        })
        .finally(() => {
          this.loadingDoctor = false
        })
    },
    async getClinicDoctor (search) {
      const params = {
        limit: 50
      }
      if (search) {
        params.search = search
      }
      this.loadingDoctor = true
      await this.$rf
        .getRequest('DoctorRequest')
        .getClinicDoctor(this.$globalClinic?.id, params)
        .then(r => {
          this.doctors = r.data.data
          this.doctors.map(d => {
            d.name = d.doctor?.name
            d.user_id = d.doctor?.user_id
          })
          const hasMe = this.doctors.find(
            d => d.doctor_id === this.$user?.doctor.id
          )
          if (!hasMe && this.$user.doctor) this.doctors.push(this.$user.doctor)
          this.doctorPaging = {
            count: r.data.count,
            current_page: r.data.current_page,
            last_page: r.data.last_page,
            per_page: r.data.per_page,
            total: r.data.total
          }
        })
        .finally(() => {
          this.loadingDoctor = false
        })
    },
    closeModal () {
      this.form = {}
    }
  }
}
</script>
<style scoped>
</style>
